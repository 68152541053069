import { Popover, TextField } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledDatePickerPopover = styled(Popover)(({ theme }) => ({
  top: theme.spacing(-4),
  left: theme.spacing(4),
  '& .MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    height: 'fit-content',
  },
}));

export const StyledDatePickerHeader = styled(Box)(({ theme }) => ({
  width: 'full',
  height: '90px',
  display: 'flex',
  padding: `${theme.spacing(4)} 0`,
  justifyContent: 'end',
  alignItems: 'center',
}));

export const StyledDatesTextField = styled(TextField)<{ width: string }>(({ theme, width }) => ({
  width: width,
  backgroundColor: 'white',
  borderRadius: theme.spacing(3),
  marginRight: '-8px',
  '.MuiInputBase-root': {
    border: `1px solid black`,
    backgroundColor: theme.token.color.neutral.background.weak[' '].value,
  },
  '.MuiInputBase-root:hover': {
    backgroundColor: theme.token.color.neutral.background.weak[' '].value,
  },
  '.MuiInputBase-root:focus': {
    backgroundColor: theme.token.color.neutral.background.weak[' '].value,
  },
  '.MuiInputBase-root:active': {
    backgroundColor: theme.token.color.neutral.background.weak[' '].value,
  },
  '& .MuiInputBase-input': {
    marginLeft: theme.spacing(1.5),
  },
  '.MuiFilledInput-root.Mui-focused::before': {
    border: 'none',
  },
}));

export const FooterStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  minHeight: '64px',
  flexWrap: 'wrap',
  borderTop: `${theme.spacing(0.25)} solid ${theme.token.color.neutral.border[' '].value}`,
  padding: `11px ${theme.spacing(6)}`,
}));

export const FooterButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  alignItems: 'center',
  flexGrow: '1',
  flexWrap: 'wrap',
}));
